import React, { useEffect, useRef, useState } from "react";
import { Container } from "react-bootstrap";
import BreadcrumbComponent from "../Breadcrumb";
import ProfileName from "./blocs/ProfileName";
import ProfilInfo from "./blocs/ProfilInfo";
import { Col, Row } from "react-bootstrap";
import { ProfilContext } from "./ProfileContext";
import "../../../assets/style/profil.scss";
import ProfilTeam from "./blocs/ProfilTeam";
import ProfilComment from "./blocs/ProfilComment";
import ProfilHistory from "./blocs/ProfilHistory";
import ProfilSuppress from "./blocs/ProfilSuppress";
import ProfilUpdate from "./blocs/ProfilUpdate";
import ProfilCreate from "./blocs/ProfilCreate";
import UpdateEmploye from "./blocs/UpdateEmploye";
import DeleteEmploye from "./blocs/DeleteEmploye";
import Loader from "../Loader";
import ErrorPage from "../../ErrorPage";
import { ArrowLeftSVG } from "../svg/arrowLeft";
import { NavLink, useNavigate } from "react-router-dom";
import ProfilSubordinates from "./blocs/ProfilSubordinates";
import ProfilStatusSync from "./blocs/ProfilStatusSync";

function Profile() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [profileData, setProfileData] = useState(null);
  const [profileSubordinates, setProfileSubordinates] = useState(null);
  const [profileHistory, setProfileHistory] = useState(null);
  const [profileCreate, setProfilCreate] = useState(null);
  const [profileUpdate, setProfilUpdate] = useState(null);
  const [profileDelete, setProfilDelete] = useState(null);

  const [isBreadcrumbVisible, setIsBreadcrumbVisible] = useState(true);
  const breadcrumbRef = useRef(null);

  const [reloadParent, setReloadParent] = useState(false);

  const handleReloadParent = () => {
    setReloadParent((prevState) => !prevState);
  };

  const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", process.env.REACT_APP_JWT_KEY);

  useEffect(() => {
    console.log("Reload parent state changed:", reloadParent);
    // Your component logic here
  }, [reloadParent]);

  useEffect(() => {
    const handleScroll = () => {
      if (breadcrumbRef.current) {
        const breadcrumbRect = breadcrumbRef.current.getBoundingClientRect();
        setIsBreadcrumbVisible(breadcrumbRect.bottom > 0);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Initial check when the component mounts
    handleScroll();

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  let slug = window.location.pathname.split("/").pop();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          process.env.REACT_APP_PROXY_URL + "employee/" + slug,
          {
            method: "GET",
            hostname: process.env.REACT_APP_HOSTNAME,
            headers: myHeaders,
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const jsonData = await response.json();

        setProfileData(jsonData.user);
        setProfileSubordinates(jsonData.subordinates);
        setProfileHistory(jsonData.history);
        setProfilCreate(jsonData.create_mode);
        setProfilUpdate(jsonData.update_mode);
        setProfilDelete(jsonData.delete_mode);

        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, [slug, reloadParent]); // Ajoutez slug dans le tableau des dépendances si vous l'utilisez dans votre useEffect

  const [showUpdateProfil, setShowUpdateProfil] = useState(false);
  const [showDeleteProfil, setShowDeleteProfil] = useState(false);

  const breadcrumbLinks = profileData
    ? [
        { label: "Accueil", to: "/" },
        { label: "Liste des collaborateurs", to: "/collaborateurs" },
        { label: profileData.first_name + " " + profileData.last_name },
      ]
    : [];

  function formatDateToDayMonthYear(originalDate) {
    const dateObject = new Date(originalDate);
    const day = dateObject.getDate();
    const month = dateObject.getMonth() + 1;
    const year = dateObject.getFullYear();
    const formattedDate = `${day}/${month}/${year}`;
    return formattedDate;
  }

  function convertDateFR(dateAuFormatISO) {
    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    const date = new Date(dateAuFormatISO);
    return date.toLocaleDateString("fr-FR", options);
  }

  return (
    <Container className="profil-page">
      {loading ? (
        <Loader />
      ) : profileData ? (
        <>
          <ProfilContext.Provider value={profileData}>
            <div id="bread-container" ref={breadcrumbRef}>
              <BreadcrumbComponent links={breadcrumbLinks} />
            </div>

            <NavLink
              title="Liste des collaborateurs"
              className="nav-link"
              aria-current="page"
              onClick={() => navigate(-1)}
            >
              <div className="fixed-back">
                <ArrowLeftSVG />
              </div>
            </NavLink>

            <Row>
              <Col lg={12} className="pl-4 pr-4">
                <ProfileName
                  updatePopVisibility={setShowUpdateProfil}
                  deletePopVisibility={setShowDeleteProfil}
                  formatDate={formatDateToDayMonthYear}
                  deleteStatus={profileDelete}
                  updateStatus={profileUpdate}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={8} className="">
                {profileCreate && profileCreate.status && (
                  <ProfilCreate
                    onReloadParent={handleReloadParent}
                    data={profileCreate}
                    idEmployee={profileData.id}
                  />
                )}
                {profileUpdate && profileUpdate.status && (
                  <ProfilUpdate
                    onReloadParent={handleReloadParent}
                    data={profileUpdate}
                    idEmployee={profileData.id}
                  />
                )}
                {profileDelete && profileDelete.status && (
                  <ProfilSuppress
                    onReloadParent={handleReloadParent}
                    data={profileDelete}
                    idEmployee={profileData.id}
                  />
                )}
                <ProfilInfo formatDate={formatDateToDayMonthYear} />
                {profileData.comment && <ProfilComment />}
              </Col>

              <Col lg={4} className="">
                <ProfilStatusSync
                  syncWP={profileData.api_sync.facebook_sync_state}
                  syncGoogle={profileData.api_sync.google_sync_state}
                  employeeID={profileData.id}
                  onReloadParent={handleReloadParent}
                />

                {profileData.manager && profileData.manager.id && (
                  <ProfilTeam />
                )}

                {profileSubordinates && profileSubordinates.length !== 0 && (
                  <ProfilSubordinates data={profileSubordinates} />
                )}

                {profileHistory && profileHistory.length !== 0 && (
                  <ProfilHistory
                    data={profileHistory}
                    formatDate={convertDateFR}
                  />
                )}
              </Col>
            </Row>
          </ProfilContext.Provider>

          {showUpdateProfil && (
            <UpdateEmploye
              onReloadParent={handleReloadParent}
              updatePopVisibility={setShowUpdateProfil}
              slugEmployee={slug}
            />
          )}

          {showDeleteProfil && (
            <DeleteEmploye
              onReloadParent={handleReloadParent}
              deletePopVisibility={setShowDeleteProfil}
              idEmployee={profileData.id}
            />
          )}
        </>
      ) : (
        <ErrorPage />
      )}
    </Container>
  );
}

export default Profile;
